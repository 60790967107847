<template>
  <v-form ref="editShareholderForm" class="max-width">
    <SelfBuildingSpinner v-if="loading" />
    <div v-else>
      <v-row class="px-10">
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.companyName"
            color="red darken-4"
            outlined
            dense
            label="Company Name"
            :error-messages="
              requiredError($v.editedValues.companyName, 'Company Name')
            "
            @change="$v.editedValues.companyName.$touch"
            @blur="$v.editedValues.companyName.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.email"
            color="red darken-4"
            outlined
            dense
            :error-messages="emailErrors"
            label="Email"
            @change="$v.editedValues.email.$touch"
            @blur="$v.editedValues.email.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.mobileNumber"
            color="red darken-4"
            outlined
            dense
            :error-messages="phoneErrors"
            label="Mobile Number"
            @change="$v.editedValues.mobileNumber.$touch"
            @blur="$v.editedValues.mobileNumber.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.registrationAddress"
            color="red darken-4"
            outlined
            dense
            :error-messages="
              requiredError(
                $v.editedValues.registrationAddress,
                'Registration address',
              )
            "
            label="Registration Address"
            @change="$v.editedValues.registrationAddress.$touch"
            @blur="$v.editedValues.registrationAddress.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-menu
            ref="menuIncorporationDate"
            v-model="incorporationDateMenu"
            :close-on-content-click="false"
            :return-value.sync="incorporationDateMenu"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="editedValues.incorporationDate"
                color="red darken-4"
                outlined
                dense
                label="Incorporation Date"
                prepend-icon="mdi-calendar"
                readonly
                :error-messages="
                  requiredError(
                    $v.editedValues.incorporationDate,
                    'Incorporation date',
                  )
                "
                v-on="on"
                @change="$v.editedValues.incorporationDate.$touch"
                @blur="$v.editedValues.incorporationDate.$touch"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editedValues.incorporationDate"
              no-title
              scrollable
              color="red darken-4"
            >
              <div class="flex-grow-1"></div>
              <v-btn
                text
                color="red darken-4"
                @click="incorporationDateMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="red darken-4"
                @click="$refs.menuIncorporationDate.save(incorporationDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="editedValues.incorporationCountry"
            color="red darken-4"
            outlined
            dense
            mandatory
            :items="countryList"
            :error-messages="
              requiredError(
                $v.editedValues.incorporationCountry,
                'Incorporation country',
              )
            "
            prepend-icon="mdi-map"
            label="Country of Incorporation"
            @change="$v.editedValues.incorporationCountry.$touch"
            @blur="$v.editedValues.incorporationCountry.$touch"
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="editedValues.registrationNumber"
            color="red darken-4"
            outlined
            dense
            label="Registration Number"
            :error-messages="
              requiredError(
                $v.editedValues.registrationNumber,
                'Registration Number',
              )
            "
            @change="$v.editedValues.registrationNumber.$touch"
            @blur="$v.editedValues.registrationNumber.$touch"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.director"
            color="red darken-4"
            outlined
            dense
            label="Director"
            :error-messages="
              requiredError($v.editedValues.director, 'Director')
            "
            @change="$v.editedValues.director.$touch"
            @blur="$v.editedValues.director.$touch"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="editedValues.remarks"
            outlined
            color="red darken-4"
            auto-grow
            label="Remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-center">
          <v-btn color="teal lighten-1" dark @click="editShareholderDetails"
            >Save New Shareholder Details</v-btn
          >
        </v-col>
      </v-row>
      <h2 class="mb-10">Edit documents<v-tooltip bottom max-width="250" color="rgba(0,0,0,.8)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="blue-grey lighten-3"
              v-bind="attrs"
              class="ml-5"
              v-on="on"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span
            >You can add directly any missing document. If you want to change an existing document, please remove first the old one clicking on the "x" next to the file name.</span
          >
        </v-tooltip></h2>
      <div
        v-for="(document, index) in editedDocuments"
        :key="`individual-document-${index}`"
      >
        <p>{{ document.label }}</p>
        <v-row class="px-10">
          <v-col v-if="document.type === 'passport'" cols="4">
            <v-text-field
              v-model="document.number"
              outlined
              dense
              :readonly="!!document.documentId"
              color="red darken-4"
              label="Document Number"
                :error-messages="
                requiredError(
                  $v.editedDocuments.$each[index].number,
                  'Document Number',
                )
              "
              @change="$v.editedDocuments.$each[index].number.$touch"
              @blur="$v.editedDocuments.$each[index].number.$touch"
            />
          </v-col>
          <v-col v-if="document.type === 'passport'" cols="4">
            <v-menu
              ref="menuDocumentExpiry"
              v-model="document.menu"
              :disabled="!!document.documentId"
              :close-on-content-click="false"
              :return-value.sync="document.menu"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="document.expiryDate"
                  color="red darken-4"
                  outlined
                  dense
                  label="Expiry Date"
                  prepend-icon="mdi-calendar"
                  readonly
                    :error-messages="
                    requiredError(
                      $v.editedDocuments.$each[index].expiryDate,
                      'Expiry Date',
                    )
                  "
                  v-on="on"
                  @change="$v.editedDocuments.$each[index].expiryDate.$touch"
                  @blur="$v.editedDocuments.$each[index].expiryDate.$touch"
                />
              </template>
              <v-date-picker
                v-model="document.expiryDate"
                no-title
                :allowed-dates="allowedDates"
                scrollable
                color="red darken-4"
              >
                <div class="flex-grow-1"></div>
                <v-btn text color="red darken-4" @click="document.menu = false"
                  >Cancel</v-btn
                >
                <v-btn
                  text
                  color="red darken-4"
                  @click="
                    $refs.menuDocumentExpiry[index].save(document.expiryDate)
                  "
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col v-if="document.documentId" cols="4">
            <v-select
              :value="document.documentName"
              :items="Array.of(document.documentName)"
              dense
              outlined
              label="File"
              readonly
              :append-icon="null"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ attrs, item, selected }">
                <v-chip
                  v-bind="attrs"
                  small
                  text-color="white"
                  color="red darken-4"
                  :input-value="selected"
                  close
                  @click:close="remove(document.documentId)"
                >
                  <span>{{ item }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col v-else cols="4">
            <v-file-input
              v-model="document.file"
              outlined
              dense
              color="red darken-4"
              accept="image/png, image/jpeg, pdf"
              show-size
              counter
              label="File input"
              @change="$v.editedDocuments.$each[index].file.$touch"
              @blur="$v.editedDocuments.$each[index].file.$touch"
            />
          </v-col>
        </v-row>
      </div>
      <div class="d-flex align-center justify-center">
        <v-btn color="teal lighten-1" dark @click="editShareholderDocuments"
          >Save New Shareholder Documents</v-btn
        >
      </div>
    </div>
  </v-form>
</template>
<script>
import { getNames } from 'country-list'
import { validationMixin } from 'vuelidate'
import { email, required, requiredIf } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
import { objectEquals } from '@src/utils/objectEquals.js'
import isAfter from 'date-fns/isAfter'
import isToday from 'date-fns/isToday'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'
import {
  mobilePhoneValidator,
  validateError,
  validExtension,
} from '@utils/validateFields'

const mandatoryDocuments = [
  'passport',
  'reference',
  'residenceProof',
  'complianceCheck',
  'personalQuestionnaire',
  'incorporationCertificate',
  'm&a',
]

export default {
  name: 'EditShareholderCorporate',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      editedValues: {
        companyName: { required },
        email: {
          required,
          email,
        },
        mobileNumber: {
          required,
          mobilePhoneValidator,
        },
        registrationAddress: {
          required,
        },
        incorporationDate: {
          required,
        },
        incorporationCountry: {
          required,
        },
        registrationNumber: {
          required,
        },
        director: {
          required,
        },
      },
      editedDocuments: {
        $each: {
          number: {
            required: requiredIf(
              ({ documentId, type }) => !documentId && type === 'passport'
            ),
          },
          expiryDate: {
            required: requiredIf(
              ({ documentId, type }) => !documentId && type === 'passport'
            ),
          },
          file: {
            required: requiredIf(
              ({ type, documentId }) =>
                !documentId && mandatoryDocuments.includes(type)
            ),
            validExtension,
          },
        },
      },
    }
  },
  props: {
    clientId: {
      type: [String, Number],
      default: 0,
    },
    shareholderId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      email: '',
      chips: '',
      editedDocuments: [],
      incorporationDateMenu: '',
      totDocuments: [
        {
          label: 'Passport/Id card',
          type: 'passport',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Bank/Professional Reference',
          type: 'reference',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Proof of residence',
          type: 'residenceProof',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Compliance Check',
          type: 'complianceCheck',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Personal Questionnaire',
          type: 'personalQuestionnaire',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Source of wealth',
          type: 'sourceWealth',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Source of funds',
          type: 'sourceFunds',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Vat certificate',
          type: 'vatCertificate',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Incorporation Certificate',
          type: 'incorporationCertificate',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'M&A',
          type: 'm&a',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Letter of engagement',
          type: 'engagementLetter',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'SIA Director',
          type: 'siaDirector',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'SIA Company Secretary',
          type: 'siaCompanySecretary',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
        {
          label: 'Authorization with MBR',
          type: 'authorizationMbr',
          number: '',
          menu: '',
          expiryDate: '',
          file: null,
        },
      ],
      initialValues: {},
      editedValues: {
        companyName: '',
        passport: '',
        nationality: '',
        residenceCountry: '',
        email: '',
        mobileNumber: '',
        address: '',
        remarks: '',
      },
      loading: false,
      documents: [],
    }
  },
  computed: {
    countryList() {
      return getNames()
    },
    emailErrors() {
      return validateError(
        this.$v.editedValues.email,
        ['required', 'email'],
        'Email is required and should be valid'
      )
    },
    phoneErrors() {
      return validateError(
        this.$v.editedValues.mobileNumber,
        ['required', 'mobilePhoneValidator'],
        'Mobile number is required and should be valid'
      )
    },
  },
  created() {
    this.getShareholderByClientId(this.clientId)
    this.getDocumentsByClientId()
  },
  methods: {
    allowedDates(val) {
      return (
        isToday(new Date(val), new Date()) || isAfter(new Date(val), new Date())
      )
    },
    remove(documentId) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Delete document',
        message: 'Are you sure to delete this document?',
        activeCancel: true,
        confirmAction: () => this.removeDocument(documentId),
      })
    },
    editShareholderDetails() {
      this.$v.editedValues.$touch()

      if (!this.$v.editedValues.$invalid) {
        if (objectEquals(this.initialValues, this.editedValues)) {
          const err = { message: 'Details are the same. No update needed' }
          EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
          return
        }

        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Edit shareholder',
          message: 'Are you sure to edit shareholder details?',
          activeCancel: true,
          confirmAction: () => this.editDetails(),
        })
      }
    },
    editShareholderDocuments() {
      this.$v.editedDocuments.$touch()
      // if (!this.$v.editedDocuments.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Edit shareholder',
          message: 'Save new shareholder documents?',
          activeCancel: true,
          confirmAction: () => this.editDocuments(),
        })
      // }
    },
    async editDocuments() {
      const documents = this.editedDocuments.filter(({ file }) => file)
      this.loading = true
      try {
        await api.updateShareholderDocuments({
          clientId: this.clientId,
          name: this.initialValues.companyName,
          documents,
          shareholderType: ClientTypes.CORPORATE,
        })
        EventBus.$emit(
          EventBusEvents.SNACKBAR_SUCCESS,
          {
            message: 'Document updated successfully!',
          },
          this
        )
        this.getDocumentsByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async editDetails() {
      const payload = {
        companyName: this.editedValues.companyName,
        email: this.editedValues.email,
        director: this.editedValues.director,
        registrationAddress: this.editedValues.registrationAddress,
        registrationNumber: this.editedValues.registrationNumber,
        shareholderType: ClientTypes.CORPORATE,
        mobileNumber: this.editedValues.mobileNumber,
        incorporationDate: this.editedValues.incorporationDate,
        incorporationCountry: this.editedValues.incorporationCountry,
        residenceCountry: this.editedValues.residenceCountry,
        remarks: this.editedValues.remarks,
        shareholderId: this.shareholderId,
        clientId: this.clientId,
      }
      this.loading = true
      try {
        await api.updateShareholderDetails(payload)
        EventBus.$emit(
          EventBusEvents.SNACKBAR_SUCCESS,
          {
            message: 'Details updated successfully!',
          },
          this
        )
        this.getShareholderByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async removeDocument(documentId) {
      try {
        await api.deleteDocument(documentId)
        this.getDocumentsByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    documentsToEdit() {
      const documents = this.totDocuments.map((document) => {
        const documentAlreadyIn = this.documents.find(
          (doc) => doc.type === document.type
        )
        if (!documentAlreadyIn) {
          return document
        }
        const {
          type,
          comment,
          documentName,
          documentNumber,
          expiryDate,
          documentId,
        } = documentAlreadyIn
        return {
          label: document.label,
          comment,
          type,
          documentName,
          number: documentNumber,
          expiryDate,
          documentId,
        }
      })
      this.editedDocuments = documents
    },
    fileErrors(file) {
      return validateError(
        file,
        ['required', 'validExtension'],
        ['File is required', 'Extension wrong, accepted: png, jpg, pdf']
      )
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    async getShareholderByClientId() {
      this.loading = true
      try {
        const shareholder = await api.getShareholderByClientId(this.clientId)
        const initialValues = {
          companyName: shareholder.companyName,
          passport: shareholder.passport,
          incorporationDate: shareholder.incorporationDate,
          incorporationCountry: shareholder.incorporationCountry,
          registrationNumber: shareholder.registrationNumber,
          director: shareholder.director,
          registrationCountry: shareholder.country,
          registrationAddress: shareholder.address,
          email: shareholder.email,
          mobileNumber: shareholder.mobileNumber,
          remarks: shareholder.remarks,
        }

        this.initialValues = { ...initialValues }
        this.editedValues = { ...initialValues }
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async getDocumentsByClientId() {
      try {
        const documents = await api.getDocumentsByClientId(this.clientId)
        this.documents = documents
        this.documentsToEdit()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
  },
}
</script>
<style>
.max-width {
  width: 100%;
}
</style>
