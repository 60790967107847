var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"editShareholderForm",staticClass:"max-width"},[(_vm.loading)?_c('SelfBuildingSpinner'):_c('div',[_c('v-row',{staticClass:"px-10"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"color":"red darken-4","outlined":"","dense":"","label":"Company Name","error-messages":_vm.requiredError(_vm.$v.editedValues.companyName, 'Company Name')},on:{"change":_vm.$v.editedValues.companyName.$touch,"blur":_vm.$v.editedValues.companyName.$touch},model:{value:(_vm.editedValues.companyName),callback:function ($$v) {_vm.$set(_vm.editedValues, "companyName", $$v)},expression:"editedValues.companyName"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"color":"red darken-4","outlined":"","dense":"","error-messages":_vm.emailErrors,"label":"Email"},on:{"change":_vm.$v.editedValues.email.$touch,"blur":_vm.$v.editedValues.email.$touch},model:{value:(_vm.editedValues.email),callback:function ($$v) {_vm.$set(_vm.editedValues, "email", $$v)},expression:"editedValues.email"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"color":"red darken-4","outlined":"","dense":"","error-messages":_vm.phoneErrors,"label":"Mobile Number"},on:{"change":_vm.$v.editedValues.mobileNumber.$touch,"blur":_vm.$v.editedValues.mobileNumber.$touch},model:{value:(_vm.editedValues.mobileNumber),callback:function ($$v) {_vm.$set(_vm.editedValues, "mobileNumber", $$v)},expression:"editedValues.mobileNumber"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"color":"red darken-4","outlined":"","dense":"","error-messages":_vm.requiredError(
              _vm.$v.editedValues.registrationAddress,
              'Registration address'
            ),"label":"Registration Address"},on:{"change":_vm.$v.editedValues.registrationAddress.$touch,"blur":_vm.$v.editedValues.registrationAddress.$touch},model:{value:(_vm.editedValues.registrationAddress),callback:function ($$v) {_vm.$set(_vm.editedValues, "registrationAddress", $$v)},expression:"editedValues.registrationAddress"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"menuIncorporationDate",attrs:{"close-on-content-click":false,"return-value":_vm.incorporationDateMenu,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.incorporationDateMenu=$event},"update:return-value":function($event){_vm.incorporationDateMenu=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"color":"red darken-4","outlined":"","dense":"","label":"Incorporation Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.requiredError(
                  _vm.$v.editedValues.incorporationDate,
                  'Incorporation date'
                )},on:{"change":_vm.$v.editedValues.incorporationDate.$touch,"blur":_vm.$v.editedValues.incorporationDate.$touch},model:{value:(_vm.editedValues.incorporationDate),callback:function ($$v) {_vm.$set(_vm.editedValues, "incorporationDate", $$v)},expression:"editedValues.incorporationDate"}},on))]}}]),model:{value:(_vm.incorporationDateMenu),callback:function ($$v) {_vm.incorporationDateMenu=$$v},expression:"incorporationDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"red darken-4"},model:{value:(_vm.editedValues.incorporationDate),callback:function ($$v) {_vm.$set(_vm.editedValues, "incorporationDate", $$v)},expression:"editedValues.incorporationDate"}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"text":"","color":"red darken-4"},on:{"click":function($event){_vm.incorporationDateMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"red darken-4"},on:{"click":function($event){return _vm.$refs.menuIncorporationDate.save(_vm.incorporationDate)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"color":"red darken-4","outlined":"","dense":"","mandatory":"","items":_vm.countryList,"error-messages":_vm.requiredError(
              _vm.$v.editedValues.incorporationCountry,
              'Incorporation country'
            ),"prepend-icon":"mdi-map","label":"Country of Incorporation"},on:{"change":_vm.$v.editedValues.incorporationCountry.$touch,"blur":_vm.$v.editedValues.incorporationCountry.$touch},model:{value:(_vm.editedValues.incorporationCountry),callback:function ($$v) {_vm.$set(_vm.editedValues, "incorporationCountry", $$v)},expression:"editedValues.incorporationCountry"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"color":"red darken-4","outlined":"","dense":"","label":"Registration Number","error-messages":_vm.requiredError(
              _vm.$v.editedValues.registrationNumber,
              'Registration Number'
            )},on:{"change":_vm.$v.editedValues.registrationNumber.$touch,"blur":_vm.$v.editedValues.registrationNumber.$touch},model:{value:(_vm.editedValues.registrationNumber),callback:function ($$v) {_vm.$set(_vm.editedValues, "registrationNumber", $$v)},expression:"editedValues.registrationNumber"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"color":"red darken-4","outlined":"","dense":"","label":"Director","error-messages":_vm.requiredError(_vm.$v.editedValues.director, 'Director')},on:{"change":_vm.$v.editedValues.director.$touch,"blur":_vm.$v.editedValues.director.$touch},model:{value:(_vm.editedValues.director),callback:function ($$v) {_vm.$set(_vm.editedValues, "director", $$v)},expression:"editedValues.director"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","color":"red darken-4","auto-grow":"","label":"Remarks"},model:{value:(_vm.editedValues.remarks),callback:function ($$v) {_vm.$set(_vm.editedValues, "remarks", $$v)},expression:"editedValues.remarks"}})],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"teal lighten-1","dark":""},on:{"click":_vm.editShareholderDetails}},[_vm._v("Save New Shareholder Details")])],1)],1),_c('h2',{staticClass:"mb-10"},[_vm._v("Edit documents"),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","color":"rgba(0,0,0,.8)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"color":"blue-grey lighten-3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v("You can add directly any missing document. If you want to change an existing document, please remove first the old one clicking on the \"x\" next to the file name.")])])],1),_vm._l((_vm.editedDocuments),function(document,index){return _c('div',{key:("individual-document-" + index)},[_c('p',[_vm._v(_vm._s(document.label))]),_c('v-row',{staticClass:"px-10"},[(document.type === 'passport')?_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":!!document.documentId,"color":"red darken-4","label":"Document Number","error-messages":_vm.requiredError(
                _vm.$v.editedDocuments.$each[index].number,
                'Document Number'
              )},on:{"change":_vm.$v.editedDocuments.$each[index].number.$touch,"blur":_vm.$v.editedDocuments.$each[index].number.$touch},model:{value:(document.number),callback:function ($$v) {_vm.$set(document, "number", $$v)},expression:"document.number"}})],1):_vm._e(),(document.type === 'passport')?_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"menuDocumentExpiry",refInFor:true,attrs:{"disabled":!!document.documentId,"close-on-content-click":false,"return-value":document.menu,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(document, "menu", $event)},"update:return-value":function($event){return _vm.$set(document, "menu", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"color":"red darken-4","outlined":"","dense":"","label":"Expiry Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.requiredError(
                    _vm.$v.editedDocuments.$each[index].expiryDate,
                    'Expiry Date'
                  )},on:{"change":_vm.$v.editedDocuments.$each[index].expiryDate.$touch,"blur":_vm.$v.editedDocuments.$each[index].expiryDate.$touch},model:{value:(document.expiryDate),callback:function ($$v) {_vm.$set(document, "expiryDate", $$v)},expression:"document.expiryDate"}},on))]}}],null,true),model:{value:(document.menu),callback:function ($$v) {_vm.$set(document, "menu", $$v)},expression:"document.menu"}},[_c('v-date-picker',{attrs:{"no-title":"","allowed-dates":_vm.allowedDates,"scrollable":"","color":"red darken-4"},model:{value:(document.expiryDate),callback:function ($$v) {_vm.$set(document, "expiryDate", $$v)},expression:"document.expiryDate"}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"text":"","color":"red darken-4"},on:{"click":function($event){document.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"red darken-4"},on:{"click":function($event){return _vm.$refs.menuDocumentExpiry[index].save(document.expiryDate)}}},[_vm._v("OK")])],1)],1)],1):_vm._e(),(document.documentId)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"value":document.documentName,"items":Array.of(document.documentName),"dense":"","outlined":"","label":"File","readonly":"","append-icon":null,"prepend-icon":"mdi-paperclip"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var attrs = ref.attrs;
                  var item = ref.item;
                  var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","text-color":"white","color":"red darken-4","input-value":selected,"close":""},on:{"click:close":function($event){return _vm.remove(document.documentId)}}},'v-chip',attrs,false),[_c('span',[_vm._v(_vm._s(item))])])]}}],null,true)})],1):_c('v-col',{attrs:{"cols":"4"}},[_c('v-file-input',{attrs:{"outlined":"","dense":"","color":"red darken-4","accept":"image/png, image/jpeg, pdf","show-size":"","counter":"","label":"File input"},on:{"change":_vm.$v.editedDocuments.$each[index].file.$touch,"blur":_vm.$v.editedDocuments.$each[index].file.$touch},model:{value:(document.file),callback:function ($$v) {_vm.$set(document, "file", $$v)},expression:"document.file"}})],1)],1)],1)}),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{attrs:{"color":"teal lighten-1","dark":""},on:{"click":_vm.editShareholderDocuments}},[_vm._v("Save New Shareholder Documents")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }