<template>
  <Layout>
    <v-card class="elevation-0" outlined>
      <v-card-title>
        <span class="text-h5">Edit shareholder</span
        ><v-tooltip bottom max-width="250" color="rgba(0,0,0,.8)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="blue-grey lighten-3"
              v-bind="attrs"
              class="ml-5"
              v-on="on"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span
            >You can directly edit any of the fields. When you are satisfied
            with the changes, remember to save your work.</span
          >
        </v-tooltip>
        <v-spacer grow></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pa-0 fill-height">
          <component
            :is="editComponent"
            :client-id="clientId"
            :shareholder-id="shareholderId"
          />
        </v-container>
      </v-card-text>
    </v-card>
  </Layout>
</template>
<script>
import EditShareholderIndividual from '../../components/Shareholders/EditShareholderIndividual.vue'
import Layout from '@layouts/main'
import EditShareholderCorporate from '../../components/Shareholders/EditShareholderCorporate.vue'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
const INACTIVE_USER_TIME_THRESHOLD = 10 * 60 * 1000 // 10 minuti
const USER_ACTIVITY_THROTTLER_TIME = 5000 // 5s
const FIVE_SECONDS = 5 * 1000 // 5s
export default {
  name: 'EditShareholder',
  components: {
    Layout,
    EditShareholderIndividual,
    EditShareholderCorporate,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    lockId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pollingId: '',
      pollingInterval: null,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
    }
  },
  computed: {
    editComponent() {
      return this.item.shareholderType === ClientTypes.INDIVIDUAL
        ? 'EditShareholderIndividual'
        : 'EditShareholderCorporate'
    },
    clientId() {
      return this.item && this.item.clientId
    },
    shareholderId() {
      return this.item && this.item.shareholderId
    },
  },
  mounted() {
    this.pollingId = this.lockId
    this.pollingInterval = setInterval(() => {
      this.refreshLock()
    }, FIVE_SECONDS)
    window.addEventListener('mousemove', this.userActivityThrottler)
    window.addEventListener('scroll', this.userActivityThrottler)
    window.addEventListener('keydown', this.userActivityThrottler)
    window.addEventListener('resize', this.userActivityThrottler)
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.userActivityThrottler)
    window.removeEventListener('scroll', this.userActivityThrottler)
    window.removeEventListener('keydown', this.userActivityThrottler)
    window.removeEventListener('resize', this.userActivityThrottler)

    if (this.userActivityThrottlerTimeout)
      clearTimeout(this.userActivityThrottlerTimeout)
    if (this.userActivityTimeout) clearTimeout(this.userActivityTimeout)

    if (this.pollingInterval) clearInterval(this.pollingInterval)
  },
  methods: {
    async refreshLock() {
      try {
        this.pollingId = await api.lockEditClientRefresh({
          clientId: this.clientId,
          previousLockId: this.pollingId,
        })
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
        this.$router.push({ name: 'shareholders' })
      }
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()

          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = null
        }, USER_ACTIVITY_THROTTLER_TIME)
      }
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout)
      this.userActivityTimeout = setTimeout(() => {
        // this.unlockEditClient()
        // inactivity - remove polling, redirect
        clearInterval(this.pollingInterval)
        this.$router.push({ name: 'clients' })
      }, INACTIVE_USER_TIME_THRESHOLD)
    },
  },
}
</script>
<style></style>
